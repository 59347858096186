import { Fragment, h } from 'preact';
import { DidomiIcon, DidomiMenu, DidomiMenuItem } from '@didomi/ui-atoms-react';
import {
  useSnackbar,
  useActiveOrganization,
  useSPARouter,
  useSidenavMethods,
} from '@didomi/utility-react';
import copy from 'copy-text-to-clipboard';
import { useOrgSettingsMenuVisibility } from '../../hooks/useOrgSettingsMenuVisibility.hook';

export const OrganizationSettingsMenu = ({
  showOrganizationSettings = false,
}: {
  showOrganizationSettings?: boolean;
}) => {
  const { displaySnackbar } = useSnackbar();
  const { isOpen: isOpenOnMobile, close: closeSidenav } = useSidenavMethods();
  const { navigateTo } = useSPARouter();
  const { organization } = useActiveOrganization();

  const {
    withUsers,
    withSSO,
    withPrivateApiKey,
    withPublicApiKey,
    withOrgSettings,
  } = useOrgSettingsMenuVisibility();
  const isMenuEmpty =
    !withUsers &&
    !withSSO &&
    !withPrivateApiKey &&
    !withPublicApiKey &&
    !withOrgSettings;

  const handleCloseSidenav = () => {
    if (isOpenOnMobile) {
      closeSidenav();
    }
  };

  const handleUserSettings = () => {
    handleCloseSidenav();
    navigateTo('/organization/users');
  };

  const handleSSOSettings = () => {
    handleCloseSidenav();
    navigateTo('/organization/sso');
  };

  const handleOrgSettings = () => {
    handleCloseSidenav();
    navigateTo('/organization/settings');
  };

  const handleAPIKey = () => {
    handleCloseSidenav();
    navigateTo('/organization/keys');
  };

  const copyPublicAPI = () => {
    handleCloseSidenav();
    copy(organization?.public_api_key);
    displaySnackbar('Public API key has been copied', {
      action: { name: 'Ok', closeInAction: true },
    });
  };

  if (isMenuEmpty) {
    return null;
  }

  return (
    <Fragment>
      <button
        id="sidenav-menu-settings-button"
        data-testid="menu-settings-button"
        data-cy="settings-menu"
        title="My account"
        class="outline-none! rounded-full border-0 flex items-center focus:(ring-2 ring-offset-2 ring-primary-blue-2)"
      >
        <DidomiIcon
          class="rounded-full border-1 border-neutral-gray-3 bg-neutral-gray-1 w-m h-m flex items-center justify-center"
          name="settings"
        />
        <span class="font-sans ml-xxs text-button-small text-primary-blue-6">
          My organization
        </span>
      </button>

      <DidomiMenu
        for="sidenav-menu-settings-button"
        menuTitle="My organization"
        placement="top"
        distance="xxs"
        attachTo="sidenav-menu-container"
        actionName={withPublicApiKey ? 'Copy Public API key' : null}
        onActionClick={copyPublicAPI}
      >
        <div class="mt-xxs">
          {!showOrganizationSettings && withOrgSettings && (
            <DidomiMenuItem
              class="mb-xxs!"
              data-cy="settings-menu-organization-settings"
              onClick={handleOrgSettings}
            >
              Settings
            </DidomiMenuItem>
          )}
          {withUsers && (
            <DidomiMenuItem
              data-cy="settings-menu-users"
              onClick={handleUserSettings}
            >
              Users & Roles
            </DidomiMenuItem>
          )}

          {withSSO && (
            <DidomiMenuItem
              data-cy="settings-menu-sso"
              onClick={handleSSOSettings}
            >
              Single Sign-on
            </DidomiMenuItem>
          )}

          {withPrivateApiKey && (
            <DidomiMenuItem
              data-cy="settings-menu-item-key"
              onClick={handleAPIKey}
            >
              Private API key
            </DidomiMenuItem>
          )}
          {showOrganizationSettings && (
            <DidomiMenuItem
              data-cy="settings-menu-item-key"
              onClick={() =>
                organization?.self_register
                  ? navigateTo('/organization/settings')
                  : navigateTo('/organization/widget-authentication')
              }
            >
              Organization settings
            </DidomiMenuItem>
          )}
        </div>
      </DidomiMenu>
    </Fragment>
  );
};
