import { Fragment, h } from 'preact';
import { useState, useCallback, useEffect } from 'preact/hooks';
import { tw } from '@didomi/ui-foundation';
import navItems from '../../data/nav-items.json';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import {
  useActiveOrganization,
  useCustomFeature,
  useHasAccessPolicies,
  useSPARouter,
  useSidenavMethods,
} from '@didomi/utility-react';
import { NavItem } from '../NavItem/NavItem';
import ReleaseFlag from '../../components/ReleaseFlag/ReleaseFlag';
import FeatureFlag from '../../components/FeatureFlag/FeatureFlag';
import CustomFeature from '../../components/CustomFeature/CustomFeature';
import { OrganizationSelectionButton } from '../OrganizationSelectionButton/OrganizationSelectionButton';
import { MyProfileMenu } from '../MyProfileMenu/MyProfileMenu';
import { NeedHelpMenu } from '../NeedHelpMenu/NeedHelpMenu';
import { OrganizationSettingsMenu } from '../OrganizationSettingsMenu/OrganizationSettingsMenu';
import { NavigationItem } from '../types/NavigationItem.type';
import RoleAccess from '../RoleAccess/RoleAccess';
import { adminAccessPolicy } from '@didomi/authorization-metadata';

const ADMIN_ACCESS_POLICIES = [adminAccessPolicy.id];

export const Sidenav = () => {
  const { organization } = useActiveOrganization();
  const { isOpen: isOpenOnMobile } = useSidenavMethods();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  // The SPA path for authenticated SPAs is always at the second position of the URL path.
  // We only need to check this part of the pathname to decide which icon to activate
  const [activePath, setActivePath] = useState(
    `/${window.location.pathname.split('/')[2] || ''}`,
  );

  const { hasAccess: hasAdminAccess } = useHasAccessPolicies(
    ADMIN_ACCESS_POLICIES,
  );
  const [hasShellMenuFeatureFlag] = useCustomFeature('shell_menu_update');

  const { navigateTo, isBlocked } = useSPARouter();

  /* istanbul ignore next */
  const setActivePathFunc = useCallback(() => {
    setActivePath(`/${window.location.pathname.split('/')[2] || ''}`);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', setActivePathFunc);
    return () => {
      window.removeEventListener('popstate', setActivePathFunc);
    };
  }, []);

  const goTo = (e: Event, path: string) => {
    if (e instanceof MouseEvent) {
      // cmd / ctrl
      if (e.metaKey || e.ctrlKey) {
        // early return to NOT prevent the default behavior (open in new tab) and NOT navigate the current tab to `path`
        return;
      }

      // prevent a full reload of the page (because of the href attribute on the <a> tag)
      e.preventDefault();
    }

    const newPage = path.length > 1 ? path : '';
    if (activePath !== newPage) {
      navigateTo(newPage);
      if (!isBlocked()) {
        setActivePath(newPage);
      }
    }
  };

  const items: NavigationItem[] = navItems as NavigationItem[];

  return (
    <Fragment>
      <div
        class={tw('hidden', {
          'block! fixed z-[1] w-full h-full pt-s bg-sidenav-background':
            isOpenOnMobile && isSmallScreen,
        })}
      />
      <aside
        id="sidenav"
        class={tw(
          'h-full flex relative block)',
          'justify-start flex-col',
          'bg-white text-primary-blue-5',
          'pt-[36px]',
          'border-r-1 border-solid border-neutral-gray-3',
          'w-20 md:(hover:w-[266px]) h-full px-xs',
          'transition-[width] duration-300 ease-in-out group-sidenav',
          {
            'absolute z-[1]': isSmallScreen,
          },
          {
            'w-0! px-0! overflow-hidden': !isOpenOnMobile && isSmallScreen,
          },
          {
            'flex w-[266px] h-full overflow-auto':
              isOpenOnMobile && isSmallScreen,
          },
        )}
      >
        <ul class="flex-1 p-0 list-none flex flex-col items-start w-full">
          {items.map((item) => (
            <ReleaseFlag flags={item.releaseFlag}>
              <FeatureFlag flags={item.featureFlag}>
                <CustomFeature flags={item.customFeature}>
                  <RoleAccess navItemId={item.id}>
                    <NavItem
                      item={item}
                      onItemSelected={goTo}
                      activePath={activePath}
                    />
                  </RoleAccess>
                </CustomFeature>
              </FeatureFlag>
            </ReleaseFlag>
          ))}
        </ul>
        {isSmallScreen && (
          <div class="flex flex-col gap-xs" id="sidenav-menu-container">
            <hr class="mt-xs" />
            <OrganizationSelectionButton />
            <div class="overflow-hidden min-w-[160px] flex flex-col gap-xxs p-2">
              <NeedHelpMenu />
              {!!organization && (
                <OrganizationSettingsMenu
                  showOrganizationSettings={
                    hasAdminAccess && hasShellMenuFeatureFlag
                  }
                />
              )}
              <MyProfileMenu />
            </div>
          </div>
        )}
      </aside>
    </Fragment>
  );
};
